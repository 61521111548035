import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { Customer } from '../pages/common/Customer/customerSlice';
import store from '../store';

const AUTH_TOKEN = 'acb875da-2dd8-4bd4-bacf-968e28ad1061_34468097';
const BASE_URL = 'https://api.clarizen.com/V2.0/services';
axios.defaults.headers.common.Authorization = AUTH_TOKEN;

export const http: AxiosInstance = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Access-Control-Allow-Origin': 'https://api.clarizen.com',
		Authorization: `Session ${AUTH_TOKEN}`,
	},
});
axiosRetry(http, {
	retries: 3,
	retryDelay: axiosRetry.exponentialDelay,
});

http.defaults.headers.post['Content-Type'] = 'application/json';

http.interceptors.request.use((config) => {
	const token = store.getState().authorization.clzsid ?? '';
	config.headers.Authorization = `Session ${token}`;
	return config;
});

http.interceptors.response.use(
	async (response: AxiosResponse): Promise<any> => {
		if (response.status >= 200 && response.status < 300) {
			return response.data;
		}
		return '';
	},
	(error: AxiosError) => {
		const {
			response,
			request,
		}: {
			response?: AxiosResponse;
			request?: XMLHttpRequest;
		} = error;
		if (response) {
			if (response.status >= 400 && response.status <= 500) {
				return response.data?.message ?? 'Error occurred';
			}

			if (response.status === 500) {
				return Promise.reject(response.data?.message);
			}
		} else if (request) {
			return null;
		}
		return Promise.reject(error);
	}
);

export const urlQuery = '/data/query';
export const urlCreateAndRetrieve = 'data/createAndRetrieve';
export const urlCreateFromTemplate = 'data/createFromTemplate';
export const urlObjects = 'data/objects';
export const urlGetUploadUrl = '/files/getUploadUrl';
export const urlUpload = '/files/upload';
export const urlPickList = '/metadata/describeMetadata'; // ?typeNames=C_ProjectRetailer

export const getCustomersQuery =
	'SELECT Name FROM Customer ORDER BY Name LIMIT 5000';
export const getRetailersQuery =
	"SELECT Name from C_LinkedInformation WHERE C_ItemType = 'Retailer' LIMIT 5000 ";
export const textField = 'text';
export const keyField = 'id';

export function parsePickListResponse(response: any) {
	const json = response;
	const items: { id: any; text: any }[] = [];
	json.entityDescriptions[0].pickups.forEach(
		(element: { value: any; description: any }) => {
			const { value, description } = element;
			const item = { [keyField]: value, [textField]: description };
			items.push(item);
		}
	);

	return items.sort((a, b) => (a.text > b.text ? 1 : -1));
}

export function parseCustomerQueryResponse(response: any) {
	const json = response;
	const items: any[] = [];
	json.entities.forEach((element: Customer) => {
		const { id, Name } = element;
		const item = { [keyField]: id, [textField]: Name };
		items.push(item);
	});

	return items.sort((a, b) => (a.text > b.text ? 1 : -1));
}

export function parseResourceResponse(response: any) {
	const json = response;
	const items: { id: any; text: any }[] = [];
	json.entities.forEach((element: { Member: { id: any; Name: any } }) => {
		const { id, Name } = element.Member;
		const item = { [keyField]: id, [textField]: Name };
		items.push(item);
	});

	return items.sort((a, b) => (a.text > b.text ? 1 : -1));
}

export function GetPickList(name: string) {
	http
		.get(`${urlPickList}?typeNames=${name}`)
		.then((response: { data: any }) => {
			return parsePickListResponse(response);
		})
		.catch((error: any) => {
			return { hasError: true, error };
		});
}

export function GetCustomers() {
	http
		.post(urlQuery, {
			q: getCustomersQuery,
		})
		.catch((error: any) => {
			return { hasError: true, error };
		});
}
