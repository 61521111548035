import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	Form,
	Field,
	FormElement,
	FieldArray,
	FieldArrayRenderProps,
} from '@progress/kendo-react-form';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
	Notification,
	NotificationGroup,
} from '@progress/kendo-react-notification';
import { Fade, Push } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { ZonedDate } from '@progress/kendo-date-math';
import '@progress/kendo-date-math/tz/America/New_York';
import { Checkbox, NumericTextBox } from '@progress/kendo-react-inputs';
import '@progress/kendo-theme-material/dist/all.css';
import { filterBy } from '@progress/kendo-data-query';
import { RootState } from '../../store';
import * as authReducer from '../../authorization/authorizationSlice';
import * as taskReducers from './taskSlice';
import * as api from '../../services/api';
import { ProjectTask, ProjectTaskField } from './task.interface';
import {
	FormComboBox,
	FormDatePicker,
	FormSwitch,
	FormTextArea,
	FormFloatingInput,
	FormNumericTextBox,
	FormInput,
	FormUpload,
} from '../../components/form-components';
import { requiredValidator } from '../../components/validators';

const renderTaskField = (
	formRenderProps: any,
	parm: ProjectTaskField,
	renderSharedField: boolean,
	elementKey?: any,
	initValue?: any
) => {
	if (parm.shared !== renderSharedField) {
		// return;
	}

	// formRenderProps.onChange(parm.api_name, {
	//     value: initValue
	// });
	switch (parm.type) {
		case 'textarea':
			return (
				<Field
					key={parm.api_name ?? elementKey ?? parm.key}
					name={parm.api_name ?? elementKey ?? parm.key}
					id={parm.api_name ?? elementKey ?? parm.key}
					label={parm.label}
					component={FormTextArea}
					max={parm.max}
					optional={!parm.required}
					validator={parm.required && requiredValidator}
				/>
			);
		case 'textfield':
			return (
				<Field
					key={parm.api_name ?? elementKey ?? parm.key}
					name={parm.api_name ?? elementKey ?? parm.key}
					id={parm.api_name ?? elementKey ?? parm.key}
					label={parm.label}
					component={FormFloatingInput}
					max={parm.max}
					optional={!parm.required}
					validator={parm.required && requiredValidator}
				/>
			);
		case 'date':
			return (
				<Field
					key={parm.api_name ?? elementKey ?? parm.key}
					name={parm.api_name ?? elementKey ?? parm.key}
					id={parm.api_name ?? elementKey ?? parm.key}
					label={parm.label}
					component={FormDatePicker}
					optional={!parm.required}
					validator={parm.required && requiredValidator}
				/>
			);
		case 'boolean':
			return (
				<Field
					key={parm.api_name ?? elementKey ?? parm.key}
					name={parm.api_name ?? elementKey ?? parm.key}
					id={parm.api_name ?? elementKey ?? parm.key}
					label={parm.label}
					component={FormSwitch}
					optional={!parm.required}
				/>
			);
		case 'number':
			return (
				<Field
					key={
						parm.key === 'QuantityPrototype' ||
						parm.key === 'QuantityMockup' ||
						parm.key === 'QuantityWhiteSample'
							? parm.key
							: parm.api_name ?? elementKey ?? parm.key
					}
					name={
						parm.key === 'QuantityPrototype' ||
						parm.key === 'QuantityMockup' ||
						parm.key === 'QuantityWhiteSample'
							? parm.key
							: parm.api_name ?? elementKey ?? parm.key
					}
					id={
						parm.key === 'QuantityPrototype' ||
						parm.key === 'QuantityMockup' ||
						parm.key === 'QuantityWhiteSample'
							? parm.key
							: parm.api_name ?? elementKey ?? parm.key
					}
					label={parm.label}
					component={FormNumericTextBox}
					optional={!parm.required}
					validator={parm.required && requiredValidator}
				/>
			);
		case 'dropdown':
			return (
				<Field
					key={parm.api_name ?? elementKey ?? parm.key}
					name={parm.api_name ?? elementKey ?? parm.key}
					id={parm.api_name ?? elementKey ?? parm.key}
					label={parm.label}
					component={FormComboBox}
					data={initValue ?? null}
					dataItemKey="id"
					textField="text"
					optional={!parm.required}
					validator={parm.required && requiredValidator}
				/>
			);
		default:
			return <div />;
	}
};

const CommonTasks: any = (fieldArrayRenderProps: FieldArrayRenderProps) => {
	const dispatch = useDispatch();
	const task = useSelector((state: RootState) => state.tasks);
	const [resourceData, setResourceData] = React.useState(
		task.allResourceData.slice()
	);
	// const uploadRef = React.createRef<Upload>();
	return (
		fieldArrayRenderProps.value &&
		fieldArrayRenderProps.value.map((element: any, index: any) => {
			return (
				<section>
					<div
						className="k-tilelayout-item k-card k-card"
						style={{ margin: 20, padding: 10 }}
					>
						<div className="k-card-title">
							{element.TaskType}
							<Button
								icon="delete"
								look="flat"
								title="Remove Task"
								primary={false}
								className="taskDelete"
								onClick={(e) => {
									e.preventDefault();
									fieldArrayRenderProps.onRemove({ index });
									dispatch(taskReducers.removeAllTasksAtIndex(index));
								}}
							/>
						</div>
						{/* <ExternalDropZone
                            uploadRef={uploadRef}
                        /> */}
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div className="taskName">
								{
									<Field
										key={`${element.key}_name`}
										id={`${element.key}_name`}
										// name={`${element.name + index}.name`}
										name={`tasks[${index}].name`}
										label="Name"
										component={FormInput}
										validator={requiredValidator}
										value={`tasks[${index}].value`}
										max={100}
									/>
								}
							</div>
							<div className="taskClassification">
								<Field
									key={`${element.key}_classification`}
									// name={`${element.name}.classification`}
									name={`tasks[${index}].classification`}
									id={`${element.key}_classification`}
									label="Classification"
									component={FormComboBox}
									data={['Final', 'Preliminary']}
									validator={requiredValidator}
								/>
							</div>
							<div className="taskDueDate">
								<Field
									key={`${element.key}_duedate`}
									// name={`${element.name}.duedate`}
									name={`tasks[${index}].duedate`}
									id={`${element.key}_duedate`}
									label="Due Date"
									component={FormDatePicker}
									validator={requiredValidator}
								/>
							</div>
							<div className="taskBillable">
								<Field
									key={`${element.key}_billable`}
									// name={`${element.name}.billable`}
									name={`tasks[${index}].billable`}
									id={`${element.key}_billable`}
									label="Billable"
									component={FormSwitch}
								/>
							</div>
							<div className="taskResource">
								<Field
									key={`${element.key}_resource`}
									// name={`${element.name}.resource`}
									name={`tasks[${index}].resource`}
									id={`${element.key}_resource`}
									label="Resource"
									component={FormComboBox}
									data={resourceData}
									dataItemKey={api.keyField}
									textField={api.textField}
									optional
									filterable
									onFilterChange={(e: any) => {
										const data = task.allResourceData.slice();
										setResourceData(filterBy(data, e.filter));
									}}
								/>
							</div>
							<div className="taskUpload">
								<Field
									key={`${element.key}_upload`}
									// name={`${element.name}.files`}
									name={`tasks[${index}].files`}
									id={`${element.key}_upload`}
									component={FormUpload}
									// ref={uploadRef}
								/>
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between' }} />

						{/* {
                                    element.fields?.map((field: any, index1: any) => {
                                        return (
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ width: '50%' }}>
                                                    {renderTaskField(field, false, `tasks[${index}].fields[${index1}].name`)}
                                                </div>
                                            </div>
                                        );
                                    })
    
                                } */}
						<div className="taskwarning">{element.notes ?? ''}</div>
					</div>
				</section>
			);
		})
	);
};

const SharedTasks = (formRenderProps: any) => {
	const task = useSelector((state: RootState) => state.tasks);
	const fields: ProjectTaskField[] = [];

	task.taskListArray.forEach((element: ProjectTask) => {
		if (task.allSelectedTasks.findIndex((e) => e.name === element.name) > -1) {
			fields.push(...element.fields);
		}
	});

	let uniqueFields: ProjectTaskField[] = fields.filter((v, i) => {
		return fields.map((val) => val.key).indexOf(v.key) === i;
	});
	uniqueFields = uniqueFields.sort((a, b) => (a.order > b.order ? 1 : -1));
	return (
		<div>
			{uniqueFields.map((element: ProjectTaskField) => {
				const a = element.api_name;
				let initData =
					task.sharedFieldsData !== undefined &&
					task.sharedFieldsData[a] !== undefined
						? task.sharedFieldsData[a]
						: '';

				if (element.type === 'dropdown') {
					initData = element.values ?? task.dropDownData[element.api_name];
				}

				return (
					<section>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ width: '50%' }}>
								{renderTaskField(
									formRenderProps,
									element,
									false,
									element.key,
									initData
								)}
							</div>
						</div>
					</section>
				);
			})}
		</div>
	);
};

const TaskDetails: FC = () => {
	const task = useSelector((state: RootState) => state.tasks);
	const navigate = useNavigate();
	const [allResourceData, setAllResourceData] = React.useState(
		task.allResourceData.slice()
	);
	const [formInitState, setFormInitState] = React.useState({
		tasks: task.allSelectedTasks,
		...task.sharedFieldsData,
	});
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		const clzsid = new URLSearchParams(location.search).get('clzsid');
		const projectId = new URLSearchParams(location.search).get('projectid');

		if (clzsid && clzsid !== '') {
			dispatch(authReducer.setclzsid(clzsid));
			const getAllUsers = async () => {
				api.http
					.post(api.urlQuery, {
						q: 'Select Name from User WHERE ExternalUser = false ORDER BY Name LIMIT 5000',
					})
					.then((response) => {
						dispatch(
							taskReducers.setAllUsers(api.parseCustomerQueryResponse(response))
						);
						setAllResourceData(api.parseCustomerQueryResponse(response));
					})
					.catch((error) => {
						taskReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
						});
					});
			};

			const getDesignEstimatingMilestone = async () => {
				api.http
					.post(api.urlQuery, {
						q: `Select ExternalID from Milestone Where Name = 'Design and Estimating' and Parent = '${projectId}' LIMIT 5000`,
					})
					.then((response) => {
						dispatch(
							taskReducers.setDesignEstimatingMilestone(
								api.parseCustomerQueryResponse(response)
							)
						);
					})
					.catch((error) => {
						taskReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
						});
					});
			};
			const getSharedFieldsData = async () => {
				const sharedFields = [] as any[];
				const dropdowns = [] as any[];
				task.taskListArray.forEach((t) => {
					t.fields.forEach((field: ProjectTaskField) => {
						if (field.shared) {
							const index = sharedFields.findIndex(
								(e: any) => e === field.api_name
							);
							if (index === -1) {
								sharedFields.push(field.api_name);
							}
							if (field.type === 'dropdown') {
								dropdowns.push(field.api_name);
							}
						}
					});
				});

				api.http
					.get(
						`${api.urlObjects + projectId}?fields=${sharedFields.join(
							', '
						)},Name,C_MPN,SKU,C_Customer.Name,State`
					)
					.then((response) => {
						dropdowns.forEach((element) => {
							if (
								response[element] !== undefined &&
								response[element] !== null &&
								response[element].id !== undefined &&
								response[element].id !== null
							) {
								response[element] = {
									id: response[element].id.substring(
										response[element].id.lastIndexOf('/') + 1
									),
									text: response[element].id.substring(
										response[element].id.lastIndexOf('/') + 1
									),
								};
							} else {
								response[element] = null;
							}
						});

						dispatch(taskReducers.setSharedFieldsData(response));
					})
					.catch((error) => {
						taskReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
						});
					});
			};

			const getDropDownData = async () => {
				// task.taskListArray.forEach((f) => {
				// f.fields.forEach((ff) => {
				// if (ff.type === "dropdown") {

				[
					{
						fKey: 'C_ShippingWithProduct',
						ddKey: 'C_WorkItemShippingwithproduct',
					},
				].forEach((f) => {
					api.http
						.get(`${api.urlPickList}?typeNames=${f.ddKey}`)
						.then((response) => {
							dispatch(
								taskReducers.setDropDownData({
									key: f.fKey,
									value: api.parsePickListResponse(response),
								})
							);
						})
						.catch((error) => {
							taskReducers.showNotification({
								showNotification: true,
								notificationMessage: error,
								notificationType: 'error',
							});
						});
				});
				// }
				// })
				// });
			};

			getAllUsers();
			getDesignEstimatingMilestone();
			getSharedFieldsData();
			getDropDownData();
		}
	}, [dispatch, location.search, task.taskListArray]);

	const onCheckBoxClick = (event: any) => {
		if (event.target.checked !== undefined) {
			dispatch(
				taskReducers.setPopupTaskSelected({
					id: event.target.id,
					checked: event.target.checked,
					quantity: null,
				})
			);
		} else {
			dispatch(
				taskReducers.setPopupTaskSelected({
					id: event.target.element.id,
					checked: null,
					quantity: event.target.value,
				})
			);
		}
	};

	// open add tasks popup
	const onAddTasksClick = () => {
		dispatch(taskReducers.toggleAddTaskPopup(!task.showAddTaskPopup));
	};

	const onAddTaskCloseClick = () => {
		dispatch(taskReducers.resetPopupTaskSelected());
		dispatch(taskReducers.toggleAddTaskPopup(!task.showAddTaskPopup));
		setFormInitState({
			tasks: task.allSelectedTasks,
			...task.sharedFieldsData,
		});
	};

	function createTasks(dataItem: any, element: any, projectId: any): any {
		return new Promise((resolve, reject) => {
			if (element && element.duedate !== null) {
				let postData = {
					// "Id": "/Task",
					// "ParentProject": projectId,
					Parent:
						task.designEstimatingMilestone !== undefined
							? task.designEstimatingMilestone
							: projectId,
					TaskType: element.TaskType,
					Name: element.name,
					C_TaskStatus: element.classification,
					DueDate: ZonedDate.fromUTCDate(element.duedate, 'America/New_York'),
					Billable: element.billable,
				};
				let projectPostData = {};
				// TODO: see if we can fix this
				// eslint-disable-next-line @typescript-eslint/no-unused-expressions
				element.fields &&
					element.fields.forEach((e: any) => {
						if (e.type === 'dropdown') {
							postData = {
								[e.api_name]: dataItem[e.api_name].id,
								...postData,
							};
							if (e.shared === true) {
								projectPostData = {
									[e.api_name]: dataItem[e.api_name].id,
									...projectPostData,
								};
							}
						} else {
							if (e.shared === true) {
								projectPostData = {
									[e.api_name]: dataItem[e.api_name],
									...projectPostData,
								};
							}
							if (
								e.key === 'QuantityWhiteSample' ||
								e.key === 'QuantityMockup' ||
								e.key === 'QuantityPrototype'
							) {
								postData = {
									[e.api_name]: dataItem[e.key],
									...postData,
								};
							} else {
								postData = {
									[e.api_name]: dataItem[e.api_name],
									...postData,
								};
							}
						}
					});
				// task added
				api.http
					.put(`${api.urlObjects}/Task`, postData)
					.then((response: any) => {
						// update project project with shared columns
						// create resource link
						if (element.resource && element.resource.id) {
							api.http.put(`${api.urlObjects}/ResourceLink`, {
								WorkItem: response.id,
								Resource: element.resource.id,
							});
						}

						if (element.files !== undefined && element.files.length > 0) {
							// we have files to process

							// loop through all files
							// TODO: see if we can fix this eslint issue
							// eslint-disable-next-line @typescript-eslint/no-unused-expressions
							element.files &&
								element.files.forEach((file: any) => {
									// create a document object
									api.http
										.post(api.urlCreateAndRetrieve, {
											entity: {
												Id: '/Document',
												Name: file.name,
											},
										})
										.then((docRes: any) => {
											// get an url to upload
											api.http
												.get(api.urlGetUploadUrl)
												.then((urlRes: any) => {
													// upload the file contents to this url
													api.http
														.post(urlRes.uploadUrl, file.getRawFile())
														.then(() => {
															api.http
																.post(api.urlUpload, {
																	DocumentId: docRes.entity.id,
																	fileInformation: {
																		fileName: file.name,
																		storage: 'Server',
																	},
																	uploadUrl: urlRes.uploadUrl,
																})
																.then(() => {
																	api.http
																		.post(api.urlCreateAndRetrieve, {
																			entity: {
																				Id: '/AttachmentLink',
																				Entity: response.id,
																				Document: docRes.entity.id,
																			},
																		})
																		.then(() => {})
																		.catch((error) => {
																			dispatch(
																				taskReducers.showNotification({
																					showNotification: true,
																					notificationMessage: error,
																					notificationType: 'success',
																					loading: false,
																				})
																			);
																		});
																});
														})
														.catch((error) => {
															dispatch(
																taskReducers.showNotification({
																	showNotification: true,
																	notificationMessage: error,
																	notificationType: 'success',
																	loading: false,
																})
															);
														});
												})
												.catch((error) => {
													dispatch(
														taskReducers.showNotification({
															showNotification: true,
															notificationMessage: error,
															notificationType: 'success',
															loading: false,
														})
													);
												});
										});
								});
						} else {
							// no files to process
							// stop loading screen
							// dispatch(
							// 	taskReducers.showNotification({
							// 		showNotification: true,
							// 		notificationMessage: 'Tasks created successfully',
							// 		notificationType: 'success',
							// 		loading: false,
							// 	})
							// );
							// navigate('/closewindow');
						}
						resolve({ response, projectPostData });
					})
					.catch((error) => {
						dispatch(
							taskReducers.showNotification({
								showNotification: true,
								notificationMessage: error,
								notificationType: 'error',
								loading: false,
							})
						);
						reject(error);
					});
			}
		});
	}

	const handleSubmitClick = () => {
		setTimeout(() => {
			const invalidElement =
				document.querySelector<Element>('.k-state-invalid');
			if (invalidElement) {
				invalidElement.getElementsByTagName('input')[0].focus();
			}
		}, 10);
	};

	const handleSubmit = async (dataItem: any) => {
		const projectId = new URLSearchParams(location.search).get('projectid');
		dispatch(taskReducers.setLoading(true));

		const bufferResults: any = [];
		let idx = 0;
		const allResults: any = [];
		for (let index = 0; index < dataItem.tasks.length; index += 5) {
			for (let limit = 0; limit < 5; limit += 1) {
				if (idx < dataItem.tasks.length) {
					bufferResults.push(
						createTasks(dataItem, dataItem.tasks[idx], projectId)
					);
				}
				idx += 1;
			}

			// pause processing all requests until we process these 5 tasks(buffer tasks)
			await Promise.all(bufferResults).then((bResults) => {
				allResults.push(...bResults);
			});
			bufferResults.splice(0, bufferResults.length);
		}

		Promise.all(allResults).then(() => {
			let allPD = {};
			allResults.forEach((element) => {
				allPD = { ...element.projectPostData, ...allPD };
			});
			api.http
				.post(api.urlObjects + projectId, allPD)
				.then(() => {
					dispatch(
						taskReducers.showNotification({
							showNotification: true,
							notificationMessage: 'Project updated successfully',
							notificationType: 'success',
							// loading: false
						})
					);
				})
				.catch((error) => {
					dispatch(
						taskReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							// loading: false
						})
					);
				});

			dispatch(
				taskReducers.showNotification({
					showNotification: true,
					notificationMessage: 'Tasks created successfully',
					notificationType: 'success',
					loading: false,
				})
			);
			navigate('/closewindow');
		});
	};

	return (
		<div>
			<div className="pageHeader"> Task Management</div>
			<div>
				{true && (
					<div>
						<div>
							<Form
								onSubmit={handleSubmit}
								onSubmitClick={handleSubmitClick}
								initialValues={formInitState}
								render={(formRenderProps) => (
									<FormElement style={{ maxWidth: 1200 }}>
										<div>
											<div className="k-form">
												{task.sharedFieldsData && (
													<div>
														<div style={{ width: '100%' }}>
															<div className="pullLeft">
																<span className="label-blue">MPN</span>{' '}
																{task.sharedFieldsData.C_MPN}
															</div>
															<div className="pullRight">
																<span className="label-blue">Customer</span>{' '}
																{task.sharedFieldsData.C_Customer.Name}
															</div>
														</div>
														<div style={{ width: '100%' }}>
															<div className="pullLeft">
																<span className="label-blue">Project Name</span>{' '}
																{task.sharedFieldsData.Name}
															</div>
															<div className="pullRight">
																<span className="label-blue">SKU</span>
																{task.sharedFieldsData.SKU}
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="k-form" style={{ maxWidth: 1200 }}>
												<Button
													className="addTasks"
													onClick={onAddTasksClick}
													primary
													type="button"
													icon="plus"
												>
													Add Tasks
												</Button>
											</div>
											<div>
												{task.showAddTaskPopup && (
													<Dialog className="taskListDialog" title="Task types">
														<p>Please select task types to add</p>
														{task.taskListArray.map((item: ProjectTask) => {
															return (
																<div>
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-between',
																		}}
																	>
																		<div
																			style={{
																				width: '75%',
																				marginRight: '50px',
																			}}
																		>
																			<Checkbox
																				id={item.name}
																				label={item.name}
																				key={item.name}
																				checked={
																					task.popupTaskSelections.findIndex(
																						(task1) => task1.name === item.name
																					) > -1
																				}
																				onClick={onCheckBoxClick}
																			/>
																		</div>
																		<div
																			style={{
																				width: '25%',
																				marginRight: '50px',
																			}}
																		>
																			<NumericTextBox
																				id={item.name}
																				key={`${item.name}_count`}
																				width={50}
																				min={1}
																				defaultValue={1}
																				value={
																					task.popupTaskSelections.find(
																						(task1) => task1.name === item.name
																					)?.quantity ?? 1
																				}
																				onChange={onCheckBoxClick}
																			/>
																		</div>
																	</div>
																</div>
															);
														})}
														<DialogActionsBar>
															<Button
																primary
																type="button"
																onClick={() => {
																	dispatch(taskReducers.showTasks());
																	dispatch(
																		taskReducers.toggleAddTaskPopup(
																			!task.showAddTaskPopup
																		)
																	);
																	dispatch(taskReducers.setAllTasks());
																	const oldTasks =
																		formRenderProps.valueGetter('tasks');
																	const newTasks = task.popupTaskSelections;
																	const finalTasks = [] as ProjectTask[];
																	Object.assign(finalTasks, oldTasks);

																	// eslint-disable-next-line vars-on-top
																	for (let i = 0; i < newTasks.length; i += 1) {
																		const idx = finalTasks.lastIndexOf(
																			newTasks[i] as ProjectTask
																		);
																		if (
																			idx === -1 ||
																			newTasks[i].quantity !== 1
																		) {
																			// not in old list
																			if (finalTasks.length === 0) {
																				// finalTasks.push({
																				//     name: newTasks[i].name,
																				//     key: newTasks[i].name,
																				// } as ProjectTask);
																				const t = task.taskListArray.find(
																					// eslint-disable-next-line @typescript-eslint/no-loop-func
																					(f) => f.name === newTasks[i].name
																				);
																				if (t !== undefined) {
																					finalTasks.push({ ...t });
																				}
																			} else {
																				for (
																					let j = 0;
																					j < finalTasks.length;
																					j += 1
																				) {
																					if (
																						finalTasks[j].name >
																						newTasks[i].name
																					) {
																						// finalTasks.splice(j, 0, {
																						//     name: newTasks[i].name
																						// } as ProjectTask);
																						const t = task.taskListArray.find(
																							(f) => f.name === newTasks[i].name
																						);
																						if (t !== undefined) {
																							finalTasks.splice(j, 0, { ...t });
																						}
																						break;
																					} else if (
																						finalTasks[j].name <=
																						newTasks[i].name
																					) {
																						// finalTasks.push({
																						//     name: newTasks[i].name
																						// } as ProjectTask);
																						const t = task.taskListArray.find(
																							(f) => f.name === newTasks[i].name
																						);
																						if (t !== undefined) {
																							finalTasks.push({ ...t });
																						}
																						break;
																					}
																				}
																			}
																		} else {
																			// finalTasks.splice(idx, 0, {
																			//     name: newTasks[i].name
																			// } as ProjectTask);
																			const t = task.taskListArray.find(
																				(f) => f.name === newTasks[i].name
																			);
																			if (t !== undefined) {
																				finalTasks.splice(idx, 0, { ...t });
																			}
																		}
																	}

																	// while (i < newTasks.length) {
																	//    while (j < oldTasks.length) {
																	//        if (oldTasks[i].name <= newTasks[i].name) {
																	//            finalTasks.push(oldTasks[j]);
																	//            j += 1;

																	//        }
																	//        else {
																	//            finalTasks.push(newTasks[i])
																	//            i += 1;
																	//        }
																	//    }

																	//    if (j == 0) {
																	//        finalTasks.push(newTasks[i]);
																	//    }
																	// }

																	formRenderProps.onChange('tasks', {
																		value: finalTasks.sort((a, b) =>
																			a.name > b.name ? 1 : -1
																		),
																	});

																	if (
																		task.sharedFieldsData !== undefined &&
																		task.sharedFieldsData !== null
																	) {
																		// eslint-disable-next-line no-restricted-syntax
																		for (const [key, value] of Object.entries(
																			task.sharedFieldsData
																		)) {
																			if (
																				formRenderProps.valueGetter(key) ===
																				undefined
																			) {
																				formRenderProps.onChange(key, {
																					value,
																				});
																			}
																		}
																	}
																}}
															>
																Add tasks
															</Button>
															<Button
																type="button"
																onClick={onAddTaskCloseClick}
															>
																Cancel
															</Button>
														</DialogActionsBar>
													</Dialog>
												)}
											</div>
										</div>
										{task.showTasks && (
											<div>
												<div>
													<section>
														<div
															className="k-tilelayout-item k-card k-card"
															style={{ margin: 20, padding: 10 }}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																}}
															>
																<div className="taskName" />
																<div className="taskClassification">
																	<Field
																		key="all_classification"
																		name="all_classification"
																		id="all_classification"
																		label="Classification"
																		component={FormComboBox}
																		data={['Final', 'Preliminary']}
																		onChange={(event) => {
																			task.allSelectedTasks.forEach(
																				(value, index) => {
																					const t = task.taskListArray.find(
																						(i) => i.name === value.name
																					);
																					if (t !== undefined) {
																						formRenderProps.onChange(
																							`tasks[${index}].classification`,
																							{
																								value: event.target.value,
																							}
																						);
																					}
																				}
																			);
																		}}
																		optional
																	/>
																</div>
																<div className="taskDueDate">
																	<Field
																		key="all_duedate"
																		name="all_duedate"
																		id="all_duedate"
																		label="Due Date"
																		component={FormDatePicker}
																		onChange={(event) => {
																			task.allSelectedTasks.forEach(
																				(value, index) => {
																					const t = task.taskListArray.find(
																						(i) => i.name === value.name
																					);
																					if (t !== undefined) {
																						formRenderProps.onChange(
																							`tasks[${index}].duedate`,
																							{
																								value: event.target.value,
																							}
																						);
																					}
																				}
																			);
																		}}
																		optional
																	/>
																</div>
																<div className="taskBillable">
																	<Field
																		key="all_billable"
																		name="all_billable"
																		id="all_billable"
																		label="Billable"
																		component={FormSwitch}
																		onChange={(event) => {
																			task.allSelectedTasks.forEach(
																				(value, index) => {
																					const t = task.taskListArray.find(
																						(i) => i.name === value.name
																					);
																					if (t !== undefined) {
																						//
																						// formRenderProps.touched this field contains if field is touched by user. This can be used to skip assigning value if a user touches task fields and uses global field to reset all fields
																						//
																						formRenderProps.onChange(
																							`tasks[${index}].billable`,
																							{
																								value: event.value,
																							}
																						);
																					}
																				}
																			);
																		}}
																	/>
																</div>
																<div className="taskResource">
																	<Field
																		key="all_resource"
																		name="all_resource"
																		id="all_resource"
																		label="Resource"
																		component={FormComboBox}
																		data={allResourceData}
																		dataItemKey={api.keyField}
																		textField={api.textField}
																		optional
																		filterable
																		onChange={(event) => {
																			task.allSelectedTasks.forEach(
																				(value, index) => {
																					const t = task.taskListArray.find(
																						(i) => i.name === value.name
																					);
																					if (t !== undefined) {
																						formRenderProps.onChange(
																							`tasks[${index}].resource`,
																							{
																								value: event.target.value,
																							}
																						);
																					}
																				}
																			);
																		}}
																		onFilterChange={(e: any) => {
																			const data = task.allResourceData.slice();
																			setAllResourceData(
																				filterBy(data, e.filter)
																			);
																		}}
																	/>
																</div>
																<div className="taskUpload" />
															</div>
														</div>
													</section>
												</div>
												<div>
													<FieldArray name="tasks" component={CommonTasks} />
												</div>
												<section>
													<div
														className="k-tilelayout-item k-card k-card"
														style={{ margin: 20, padding: 10 }}
													>
														<div className="k-listview-content">
															<SharedTasks formRenderProps={formRenderProps} />
														</div>
													</div>
												</section>
												{/* {(formRenderProps.visited &&
                                                    formRenderProps.errors &&
                                                    Object.entries(formRenderProps.errors).length > 0) ? (
                                                    <div style={{ paddingTop: '10px' }}>
                                                        {
                                                            Object.entries(formRenderProps.errors).map(
                                                                ([key, value]) => {
                                                                    let a = [] as any[];
                                                                    return (
                                                                        value &&
                                                                        <div>
                                                                            {
                                                                                task.taskListArray.map((f) => {
                                                                                    return (
                                                                                        f.fields.forEach((ff) => {
                                                                                            a.push(ff.api_name === key
                                                                                                ? ff.label
                                                                                                : (key.indexOf("classification") > -1
                                                                                                    ? "Classification" :
                                                                                                    (key.indexOf("duedate") > -1
                                                                                                        ? "Due Date"
                                                                                                        : '')))
                                                                                        })
                                                                                    )
                                                                                }).filter((val, id, array) => array.indexOf(val) === id)
                                                                            }
                                                                            {
                                                                                a.filter((val, id, array) => array.indexOf(val) === id)
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            ).filter((val, id, array) => array.indexOf(val) === id).filter((f) => f !== undefined && f !== "").length > 0 ? <div className={"k-messagebox k-messagebox-error"}>
                                                                Please fill in below required values
                                                                {
                                                                    Object.entries(formRenderProps.errors).map(
                                                                        ([key, value]) => {
                                                                            let a = [] as any[];
                                                                            return (
                                                                                value &&
                                                                                <div>
                                                                                    {
                                                                                        task.taskListArray.map((f) => {
                                                                                            return (
                                                                                                f.fields.forEach((ff) => {
                                                                                                    a.push(ff.api_name === key
                                                                                                        ? ff.label
                                                                                                        : (key.indexOf("classification") > -1
                                                                                                            ? "Classification" :
                                                                                                            (key.indexOf("duedate") > -1
                                                                                                                ? "Due Date"
                                                                                                                : '')))
                                                                                                })
                                                                                            )
                                                                                        }).filter((val, id, array) => array.indexOf(val) === id)
                                                                                    }
                                                                                    {
                                                                                        a.filter((val, id, array) => array.indexOf(val) === id)
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    ).filter((val, id, array) => array.indexOf(val) === id)
                                                                }
                                                            </div> : ''
                                                        }
                                                    </div>
                                                ) : ''
                                                } */}
												<div className="k-form-buttons k-buttons-end">
													<Button
														primary
														type="submit"
														// disabled={!formRenderProps.allowSubmit}
													>
														Create Tasks
													</Button>
													{/* <Button onClick={formRenderProps.onFormReset}>
                                                        {"Clear"}
                                                    </Button> */}
												</div>
											</div>
										)}
									</FormElement>
								)}
							/>
						</div>
					</div>
				)}
			</div>
			<div>
				<NotificationGroup
					style={{ top: 0, right: 0, alignItems: 'flex-end', zIndex: 999999 }}
				>
					<Fade enter exit>
						{task && task.apiError && (
							<Notification type={{ style: 'error', icon: true }}>
								Error Notification
							</Notification>
						)}
					</Fade>
					<Push
						enter
						exit
						onEntered={() =>
							setTimeout(() => {
								taskReducers.showNotification({
									showNotification: false,
									notificationMessage: '',
									notificationType: null,
								});
							}, 3000)
						}
					>
						{task && task.showNotification && (
							<Notification
								type={{
									style: task.notificationType,
									icon: true,
								}}
								onClose={() =>
									taskReducers.showNotification({
										showNotification: false,
										notificationMessage: '',
										notificationType: null,
									})
								}
							>
								{task && task.notificationMessage}
							</Notification>
						)}
					</Push>
				</NotificationGroup>
				{task.loading && (
					<Loader
						className="loader-wrapper"
						size="large"
						type="converging-spinner"
					/>
				)}
			</div>
		</div>
	);
};

export default TaskDetails;
